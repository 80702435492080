@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap');

/* ======================================================= Custom colors ====================================================== */

*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Poppins',sans-serif;
}

:root{
    --bgcolor:#f1f5fd;
    --primary-color:#3e55a8;
    --text-color:#828286;
    --main-text-color:#333333;
    --shadow-color: #b7caf7;
}

html{
    font-size: 62.5%;
}

html::-webkit-scrollbar{
    width:0.45vw;
}

html::-webkit-scrollbar-thumb{
    background-color: var(--primary-color);
    border-radius:1.5vw;
}

html::-webkit-scrollbar-track{
    background-color: var(--bgcolor);
}

a{
    text-decoration:none;
    color:#ffffff;
}

h1,h2,h3,h4,h5,h6{
    color:#333333;
}

/* ================================================== Navbar ================================================================ */
.navbar-bg{
    background-color:var(--bgcolor);
    padding:2rem 0;
    z-index:99;
    position:fixed;
    top:0;
    left:0;
    width:100%;
    height:8rem;
    padding-bottom:0;
}

.navbar-brand{
    font-size:2.2rem;
}

.navbar li {
    padding:0 2rem;
}

.navbar li a{
    font-size:1.5rem;
    font-weight:normal;
    position:relative;
}

.navbar li a:hover{
    color:var(--primary-color) !important;
    text-shadow: 0 0.1rem 0.2rem #a399e8;
}

/* =============================================================== Hero Section ========================================================== */
header{
    background-color: var(--bgcolor);
    min-height: 100vh;
    width:100%
}

.main-hero-container{
    margin-top:8rem;
    padding-top:2rem;
    height:80vh;
    display:flex;
}

.main-hero-container h1{
    font-weight:bolder;
    margin:0 1rem;
}

.main-hero-para{
    font-size:1.6rem;
    color: var(--text-color);
    margin: 3rem 0 5rem 1rem;
    font-weight:lighter;
    width:85%;
    text-align: justify;
}

.input-group{
    position:relative !important; 
    margin:0 0.5rem;
}

.button-style{
    color:#fff;
    background-color: var(--primary-color);
    height:4rem;
    width: 18rem;
    border-radius: 2rem!important;
    text-align: center;
    justify-content: center;
    display:flex;
    align-items: center;
    font-size: 1.6rem;
    padding:2rem 0;
}

.button-style:first-child{
    margin-right: 2rem;
}

.button-style:hover{
    border: 0.1rem solid var(--primary-color);
    background-color: transparent;
    color: var(--primary-color);
    box-shadow: 0 0.2rem 0.5rem var(--shadow-color);
}

/* ------------------------ Right side of Hero Section ----------------------------------- */
.main-hero-image{
    position:relative;
}

.main-hero-image img{
    width:88%;
    height:90%;
    margin-top:70px;
    /* box-shadow: 0 0.8rem 0.8rem #cfdbf8; */
    border-radius:2rem;
    position:absolute;
}

/* ============================================================== About ================================================================= */
.about-container{
    width:85vw;
    padding: 10rem 6rem 6rem 6rem;
    background-color: #fff;
    border-radius: 0.7rem;
    margin-bottom: 5rem;
}

.about-container .main-heading{
    font-size:3rem;
    font-weight:bolder;
    margin-bottom:4rem;
    word-spacing:0.6rem;
}

.about-container .about-para{
    font-size:1.8rem;
    color: var(--text-color);
    margin: 3rem 0 5rem 0;
    font-weight:light;
    text-align: justify;
}

.about-container-subdiv{
    padding: 0 3.5rem;
}

.about-container .subheading{
    font-size:2.3rem;
    font-weight:normal;
}

.about-container .fontawesome-style{
    font-size:2.5rem;
    color:var(--primary-color);
    width:7.5rem;
    height:7.5rem;
    background-color: var(--bgcolor);
    border-radius:50%;
    display:grid;
    place-items: center;
    text-align: center;
    margin:2rem auto;
}

.about-container .main-subheading{
    font-size:2.6rem;
    font-weight:bolder;
    margin-bottom:4rem;
    word-spacing:0.6rem;
}

.researchAreas-subdiv{
    box-shadow: 0 0.1rem 0.4rem var(--shadow-color);
    margin:1.5rem 0;
    border-radius: 20px;
}

.researchAreas-subdiv:hover{
    background-color: var(--bgcolor);
    cursor:pointer;
}

.researchAreas-subdiv:hover > .researchAreasIcon{
    background-color: #ffffff !important;
}

.researchAreas .researchAreas-subdiv .researchAreaHeading{
    font-size:1.8rem;
    font-weight:500;
    padding-top:5.2rem;
    padding-left:0.5rem;
    float:left;
    display: inline-block;
    text-align: right;
    vertical-align: bottom;
    line-height: 1.22;
}

.researchAreas .researchAreas-subdiv .researchAreasIcon{
    text-align: left;
    font-size:3rem;
    color:var(--primary-color);
    width:7rem;
    height:7rem;
    background-color: var(--bgcolor);
    border-radius:50%;
    display:inline-block;
    place-items:center;
    align-items: center;
    float:left;
    margin:2.5rem 1rem;
    padding-left: 2.1rem;
    padding-top: 2rem;
    vertical-align: bottom;
    line-height: 1.22;
}

@media(min-width:1400px){
    .researchAreas .col-xxl-6{
        flex: 0 0 auto;
        width:45% !important;
        margin:auto;
    }
}

@media(min-width: 992px){
    .researchAreas .col-lg-6{
        flex: 0 0 auto;
        width:45% !important;
        margin: 2rem auto;
    }
}

/* =============================================== Highlights ============================================================================== */
.highlights{
    background-color: var(--bgcolor);
    padding:9rem 6rem 6rem 6rem;
    margin:0;
}

.highlights .heading{
    font-size:3rem;
    font-weight:bold;
}

.highlights .highlightsList{
    margin:1.25rem 0;
}

.highlights .highlightsList li{
    background-color: var(--bgcolor) !important;
    /* box-shadow: 0 0.1rem 0.4rem var(--shadow-color); */
    margin: 0.5rem 0;
    border:none;
}

.highlights .highlightsList li .listItem{
    font-size:1.9rem;
}

.highlights .highlightsList li .listItem a{
    text-decoration: none;
    color: var(--primary-color);
    font-weight: 500;
    font-style: italic;
}

.highlights .button-style{
    color:#fff;
    background-color: var(--primary-color);
    height:4rem;
    width: 22rem;
    border-radius: 2rem!important;
    text-align: center;
    justify-content: center;
    display:flex;
    float:right;
    align-items: center;
    font-size: 1.6rem;
    padding:2rem 0;
}

.highlights .button-style:hover{
    border: 0.1rem solid var(--primary-color);
    background-color: transparent;
    color: var(--primary-color);
    box-shadow: 0 0.2rem 0.5rem var(--shadow-color);
}

.highlights .highlight-card{
    background-color: var(--primary-color);
    color:#fff;
    padding: 4.5rem;
    margin: 3.5rem auto;
    border-radius:25px;
    box-shadow: 0 0.7rem 1.2rem var(--shadow-color);
}

.highlights .highlight-card .card-heading{
    color:#fff;
    font-size:3rem;
    font-weight: 500;
}

.highlights .highlight-card .card-counter{
    font-size:4.5rem;
    padding:1rem 0;
    display: flex !important;
    font-weight:600;
    justify-content: center;
    text-align: center !important;
}

.highlights .highlight-card .card-content{
    font-size:1.5rem;
    text-align: justify;
}



/* ============================================ Recent Publications ================================================================ */
.recent-pubs{
    padding: 9rem 6rem 6rem 6rem;
}

.recent-pubs .heading{
    text-align: center;
    font-size:3rem;
    font-weight:bold;
    padding: 2rem 0;
}

.recent-pubs .pub-item{
    padding:0.1rem 0;
}

.recent-pubs .pub-item .pub-header .pub-title{
    font-size:2rem;
    text-align: justify;
    font-weight: 600;
}

.recent-pubs .pub-item .pub-header .pub-authors{
    font-size:1.4rem;
    color:#000;
}

.recent-pubs .pub-item .pub-header .pub-venue{
    font-size:1.4rem;
    font-weight: 500;
    color:#000;
}

.recent-pubs .pub-item .pub-body .pub-abstract{
    font-size:1.5rem;
    text-align: justify;
}

.recent-pubs .pub-item .pub-body .view-pub-btn{
    color:#fff;
    background-color: var(--primary-color);
    height:4rem;
    width: 15rem;
    border-radius: 2rem!important;
    text-align: center;
    justify-content: center;
    display:flex;
    align-items: center;
    font-size: 1.6rem;
    padding:2rem 0;
}
.recent-pubs .pub-item .pub-body .view-pub-btn:hover{
    border: 0.1rem solid var(--primary-color);
    background-color: transparent;
    color: var(--primary-color);
    box-shadow: 0 0.2rem 0.5rem var(--shadow-color);
}

.recent-pubs .all-pub-btn {
    color:#fff;
    background-color: var(--primary-color);
    height:4rem;
    width: 22rem;
    border-radius: 2rem!important;
    text-align: center;
    justify-content: center;
    display:flex;
    float:right;
    align-items: center;
    font-size: 1.6rem;
    padding:2rem 0;
    margin: 1rem 0;
}

.recent-pubs .all-pub-btn:hover{
    border: 0.1rem solid var(--primary-color);
    background-color: transparent;
    color: var(--primary-color);
    box-shadow: 0 0.2rem 0.5rem var(--shadow-color);
}

/* ============================================ People =========================================================== */
.people{
    background-color: var(--bgcolor);
    padding: 10rem 5rem 5rem 5rem;
}

.people-heading{
    text-align: center;
    font-size:3rem;
    font-weight:bold;
}

.people .director{
    padding: 2rem;
}

.people .director .people-subheading{
    text-align: left;
    font-size: 2.5rem;
    font-weight: bold;
}

.people .director .director-para{
    font-size:1.6rem;
    text-align: justify;
    padding-right:1rem;
}

.people .director .director-img{
    width:28vw;
    border-radius: 1rem;
    box-shadow: 0 0.2rem 0.5rem var(--shadow-color);
}

.people .director .btn-team {
    color:#fff;
    background-color: var(--primary-color);
    height:4rem;
    width: 35rem;
    border-radius: 2rem!important;
    text-align: center;
    justify-content: center;
    display:flex;
    float:right;
    align-items: center;
    font-size: 1.6rem;
    padding:2rem 0;
    margin: 2rem 0;
}

.people .director .btn-team:hover{
    border: 0.1rem solid var(--primary-color);
    background-color: transparent;
    color: var(--primary-color);
    box-shadow: 0 0.2rem 0.5rem var(--shadow-color);
}


/* ========================================================= Research Team ==================================================================== */
.researchTeam{
    padding: 9rem 6rem 6rem 6rem;
}

.researchTeam .heading{
    text-align: center;
    font-size:3rem;
    font-weight:bold;
    padding: 2rem 0;
}

.researchTeam .memberType{
    text-align: justify;
    font-size:2.3rem;
    font-weight:bold;
    padding-top: 2rem;
}

.researchTeam .profileImage{
    text-align: left;
    font-size:3rem;
    width:16rem;
    height:16rem;
    border-radius:5%;
    display:inline-block;
    place-items:center;
    align-items: center;
    float:left;
    margin:2.5rem 1rem;
    vertical-align: bottom;
    line-height: 1.22;
}

.researchTeam .memberDetails{
    padding-top:5.2rem;
    padding-left:0.5rem;
    float:left;
    display: inline-block;
    text-align: left;
    vertical-align: bottom;
    line-height: 1.22;
}

.researchTeam .memberDetails h4{
    font-size:2rem;
    font-weight:500;
}

.researchTeam .memberDetails h5{
    font-size:1.5rem;
    font-weight:400;
    overflow-wrap: break-word;
}

.researchTeam .memberDetails .linkedInLogo{
    text-align: left;
    font-size:3rem;
    width:2vw;
    display:inline-block;
    /* border-radius:8px; */
    place-items:center;
    align-items: center;
    vertical-align: bottom;
    line-height: 1.22;
}


/* ========================================================= Funding Agencies ================================================================= */
.funding-agencies{
    padding:10rem 6rem 6rem 6rem;
    min-height: 70vh;
    justify-content: center;
    align-items: center;
}

.funding-agencies .heading{
    text-align: center;
    font-size:3rem;
    font-weight:bold;
}

.funding-agencies .fundingContainer{
    justify-content: center;
    align-items: center;
    width:100%;
    margin-top:3.5rem;
    padding: 2rem 2rem;
}

.funding-agencies .fundingScroll{
    width:100%;
    margin: 0 2rem;
}

.funding-agencies .fundingAgencyBox{
    box-shadow:0 1rem 2rem var(--shadow-color);
    border-radius: 1rem;
    width: 52rem;
    height: 28rem;
    margin: 5rem;
    align-items: center;
    justify-content: center;
    padding:3rem 2rem 3rem 2rem;
    transition: 200ms ease-in-out;
}

.funding-agencies .fundingAgencyBox:hover{
    transform:scale(1.2);
}

.funding-agencies .fundingAgencyBox>img{
    width:48rem;
}

.funding-agencies .fundingAgencyBox .agencyName {
    text-align: center;
    font-size: 2rem;
    padding-top:1.5rem;
}

/* ============================================================= Join Us ===================================================================== */
.join-us{
    padding: 9rem 6rem 6rem 6rem;
}

.join-us .heading{
    text-align: center;
    font-size:3rem;
    font-weight:bold;
    padding: 2rem 0;
}

.join-us .job-item{
    padding:0.1rem 0;
}

.join-us .job-item .job-header .job-title{
    font-size:2rem;
    text-align: justify;
    font-weight: 600;
}


.join-us .job-item .job-header .job-subheading{
    font-size:1.4rem;
    font-weight: 500;
    color:#000;
}

.join-us .job-item .job-body .job-desc{
    font-size:1.5rem;
    text-align: justify;
}

/* ============================================================= Contact Us =================================================================== */
.contact-us{
    padding: 1.5rem 4rem;
    height:70vh;
    justify-content: center;
    align-items: center;
    background-color:var(--bgcolor);
}

.contact-us .heading{
    font-size:3rem;
    text-align: center;
    font-weight:bold;
}

.contact-us .para{
    font-size: 2rem;
    font-weight:normal;
    text-align: center;
}

.contact-us .left > img{
    min-width:90%;
    height:auto;
}

.contact-us .right > form input{
    height:4.5rem;
    font-size: 1.4rem;
    border-radius: 0.5rem;
    margin-top:1.25rem;
}

.contact-us .right > form textarea{
    height:18rem;
    font-size: 1.4rem;
    border-radius:0.5rem;
    margin-top:1.25rem;
}

.contact-us .right > form .submit-contactForm{
    color:#fff;
    background-color: var(--primary-color);
    height:4rem;
    width: 15rem;
    border-radius: 2rem!important;
    text-align: center;
    justify-content: center;
    display:flex;
    float:left;
    align-items: center;
    font-size: 1.6rem;
    padding:2rem 0;
    margin: 1.5rem 0;
}

.contact-us .right > form .submit-contactForm:hover{
    border: 0.1rem solid var(--primary-color);
    background-color: transparent;
    color: var(--primary-color);
    box-shadow: 0 0.2rem 0.5rem var(--shadow-color);
}

/* ================================================== Footer ================================================================================== */
.footer{
    padding-top: 3.5rem;
    padding-bottom: 1rem;
    background-color:#243266;
    color:#fff;
}

.footer li {
    list-style:none;
    font-size: 1.5rem;
    font-weight: lighter;
    color:#fff;
    cursor: pointer;
    margin:1rem 0;
}

.footer li a:hover{
    color: #e3e5fa !important;
}

.footer .para{
    font-size: 1.45rem;
    margin:0;
}

.footer a{
    text-decoration: none;
}

.footer a:hover{
    color: #e3e5fa !important;
}

/* ======================================================= Back To Top ======================================================================== */
.backToTop {
    position:fixed;
    right:1rem;
    bottom:6rem;
    height:6rem;
    font-size: 6rem;
    z-index:999;
}

.backToTop button{
    outline:none;
    border:none;
    cursor:pointer;
    background:none;
    padding:2rem;
}

.backToTop button .icon {
    font-size: 5rem;
    color: var(--primary-color);
}

/* ============================================================= Error Page ========================================================================= */
#notFound{
    position:relative;
    height:100vh;
    color:#000;
}

#notFound .notFound{
    position:absolute;
    left:50%;
    top:50%;
    -webkit-transform:translate(-50%,-50%);
    -ms-transform:translate(-50%,-50%);
    transform:translate(-50%,-50%);
    line-height:1.4;
    text-align: center;
    padding:0 1.5rem 0 1.5rem;
}

#notFound .notFound p{
    font-size: 1.5rem;
    font-weight:400;
    margin-top:-1.5rem;
}

#notFound .notFound a{
    font-size:1.5rem;
    text-decoration: none;
    background-color: var(--primary-color);
    display: inline-block;
    padding:1.6rem 3.8rem;
    border: 2px solid transparent;
    border-radius: 4rem;
    color:#fff;
    font-weight: 400;
    -webkit-transition: 0.2s all;
    transition: 0.2s all;
}

#notFound .notFound a:hover{
    border: 0.1rem solid var(--primary-color);
    background-color: transparent;
    color: var(--primary-color);
    box-shadow: 0 0.2rem 0.5rem var(--shadow-color);
}

/* =========================================== Responsive Media Queries ======================================================================= */
@media(min-width:1500px){
    .container,
    .container-lg,
    .container-md,
    .container-sm,
    .container-xl,
    .container-xxl{
        max-width:1500px !important;
    }
}

@media (min-width:1400px){
    .container,
    .container-lg,
    .container-md,
    .container-sm,
    .container-xl,
    .container-xxl{
        max-width:1400px !important;
    }
}

@media(max-width:1360px) {
    .people .director .director-img{
        width:25vw;
    }
}

@media (max-width:992px){
    .main-hero-image img{
        width: 50vw;
        height: 50vh;
        margin-top:10rem;
    }

    .about-container{
        margin-top:-0.05rem;
    }

    .people .director .director-img{
        width:60vw;
    }

    .contact-us .left > img{
        display: none;
    }

    .contact-us{
        padding-bottom: 2rem;
    }
}
